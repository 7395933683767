export const main = {
  colors: {
    black: '#373737',
    white: '#fff',
    danger: '#AB0505',
    info: '#5c8eff',
    pending: '#5c8eff',
    success: '#09a500',

    successLight: '#E9FBF0',
    successDark: '#61B082',
    errorLight: '#FCE4E4',
    errorDark: '#CC0033',
    infoLight: '#E4EBFD',
    infoMain: '#5C8EFF',
    infoDark: '#173A8B', // 173A8B 3364D7

    gray100: '#f1f3f4',
    gray200: '#ECECEC',
    gray300: '#DCDCDC',
    gray400: '#C4C4C4',
    gray400Hover: '#B4B4B4',
    gray600: '#A4A4A4',
    gray800: '#707070',

    greyFont: '#8C8C8C',

    primary: '#3b75ce',
    secondary: '#A4A4A4',
    secondaryText: '#757575', // Make text color darker for accessibility purposes
  },

  font: {
    size: {
      small: '1.3rem',
      medium: '1.4rem',
      semilarge: '1.5rem',
      large: '1.6rem',
      xlarge: '1.7rem',
      xxlarge: '1.8rem',
    },
    weight: {
      regular: '300',
      bold: '600',
      bolder: '700',
      xbold: '800',
    },
  },
  /* offset-x | offset-y | blur-radius | spread-radius | color */
  baseBoxShadow: '1px 1px 2px 0px #00000010',
  floatingBoxShadow: '2px 2px 6px 1px #00000025',

  mainPagePadding: '.5em 1em',
  cardPadding: '22px 20px',
  cardBorder: '1px solid #00000015',

  inputBorderRadius: '6px',
  sidebarWidth: '250px',
  transition: '.2s',

  menuIconSize: 20,
  setPrimaryColor: (color) => {
    main.colors.primary = color;
  },

};
