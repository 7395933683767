// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import UserInterfaceReducer from './UserInterfaceReducer';
import HospitalReducer from './HospitalReducer';
import UploadingStudiesReducer from './UploadingStudiesReducer';
import ProviderReducer from './ProviderReducer';

export default combineReducers({
  userInterface: UserInterfaceReducer,
  hospital: HospitalReducer,
  provider: ProviderReducer,
  uploadingStudies: UploadingStudiesReducer,
});
