import { createMuiTheme } from '@material-ui/core/styles';
import { grey, blue } from '@material-ui/core/colors';

export const getTheme = (hospital) => {
  const primaryColor = (hospital && hospital.mainColor) ? hospital.mainColor : blue[700];

  return createMuiTheme({
    typography: {
      fontFamily: 'Muli',
      fontSize: 20,
      root: {
        color: 'red',
      },
    },
    palette: {
      primary: { main: primaryColor },
      secondary: { main: grey[100] },
      background: {
        paper: '#fff',
      },
      error: {
        main: '#d72018',
      },
    },
    shape: {
      borderRadius: 6,
    },
  });
};

export default getTheme;
